import React from "react";
import { Box, Container, Text, SimpleGrid, AspectRatio } from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";

const AboutUs = () => {
  return (
    <>
      <Box>
        <StaticImage
          layout="fullWidth"
          objectFit="contain"
          src="../../images/banner/aboutus-wide.png"
          alt="about-us"
          loading="lazy"
        />
      </Box>
      <Container
        maxW={{ lg: "container.md", xl: "container.xl" }}
        pt={10}
        pb={{ base: 20, md: 40 }}>
        <Text fontWeight="bold" fontSize={{ base: "22px", md: "24px" }} mb={10}>
          About Us
        </Text>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
          <Box>
            <Text fontWeight="bold" fontSize={{ base: "20px", md: "22px" }}>
              ECO-CONSCIOUS <br />
              BEAUTY BRAND
            </Text>
            <Text fontSize={{ base: "16px", md: "18px" }} mt={4}>
              Along the way in achieving your dream, there are obstacles and challenges. Regardless
              of the existing limitations, we believe you can break through all of it. Beauty and
              ambition seems to merged, it can be expressed with confident, long lasting spirit, and
              make up that is always on.
            </Text>
            <Text fontSize={{ base: "16px", md: "18px" }} mt={4}>
              OMG-Oh My Glam keeps your deepest dream and desire to neverfade
            </Text>
          </Box>
          <Box>
            <AspectRatio maxW="560px" ratio={16 / 9}>
              <iframe
                title="about-us oh-my-glam"
                src="https://www.youtube.com/embed/YAo6RXY5OBI"
                allowFullScreen
              />
            </AspectRatio>
          </Box>
        </SimpleGrid>
        <SimpleGrid mt={16} columns={{ base: 1, md: 2 }} spacing={10}>
          <Box order={{ base: 2, md: 1 }}>
            <AspectRatio maxW="560px" ratio={16 / 9}>
              <iframe
                title="about-us oh-my-glow"
                src="https://www.youtube.com/embed/JRgfWwholGw"
                allowFullScreen
              />
            </AspectRatio>
          </Box>
          <Box order={{ base: 1, md: 2 }}>
            <Text fontWeight="bold" fontSize={{ base: "20px", md: "22px" }}>
              SKIN-LOVING <br />
              EARTH CARING
            </Text>
            <Text mt={4} fontSize={{ base: "16px", md: "18px" }}>
              Along the way in achieving your dream, there are obstacles and challenges. Regardless
              of the existing limitations, we believe you can break through all of it. Beauty and
              ambition seems to merged, it can be expressed with confident, long lasting spirit, and
              make up that is always on.
            </Text>
            <Text mt={4}>
              OMG-Oh My Glam,{" "}
              <Text fontWeight="bold" as="span" fontSize={{ base: "16px", md: "18px" }}>
                #NEVERFADE
              </Text>
            </Text>
          </Box>
        </SimpleGrid>
      </Container>
    </>
  );
};

export default AboutUs;
