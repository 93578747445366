import React from "react";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import AboutUsComponent from "../../components/about-us/AboutUs";

const AboutUs = () => {
  return (
    <Layout>
      <Seo title="About Us" />
      <AboutUsComponent />
    </Layout>
  );
};

export default AboutUs;
